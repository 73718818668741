import React from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavLink,
    } from 'reactstrap';

class MenuComponent extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <div>
            <Navbar color="navbar navbar-toggleable-md navbar-inverse  bg-primary" light expand="md">
              <NavbarBrand href="/">Oscars hemsida</NavbarBrand>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                <NavLink href="/">Hem</NavLink>
                <NavLink href="/sidor/djur/gumzan/">Gumzan</NavLink>
                <NavLink href="/sidor/djur/tage/">Tage</NavLink>
                <NavLink href="/sidor/djur/harry/">Harry</NavLink>
                <NavLink href="/sidor/djur/alfons/">Alfons</NavLink>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        )
    }
}

export default MenuComponent;